.navlinks__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: "Gilroy-Bold";
  z-index: 20 !important;
  position: fixed;
  margin-top: 10px;
  margin-left: 55px;

  @media (max-width: 900px) {
    display: none;
  }

  .navlink {
    margin: 0 45.3px;
    cursor: pointer;
    width: 41px;
    display: flex;
    justify-content: center;
    transition: color 0.3s ease-in-out, background-image 0.3s ease-in-out,
      transform 0.3s ease-in-out;

    @media (min-width: 1536px) {
      width: 85px;
    }

    /* Define initial state for background and slashes */
    background-image: none;
    transform: scale(1);
    &::before,
    &::after {
      content: "/";
      color: transparent;
      font-weight: bolder;
      position: relative;
      opacity: 0;
      transition: color 0.3s ease-in-out, left 0.3s ease-in-out, right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
    
    &:hover {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(0, 229, 255, 1) 27%,
        rgba(255, 0, 199, 1) 52%,
        rgba(255, 199, 0, 1) 80%
      );
      background-clip: text;
      transform: scale(1.1);

      &::before {
        opacity: 1;
        color: white !important;
        left: 0px;
      }

      &::after {
        opacity: 1;
        color: white !important;
        right: 0px;
      }
    }

    /* Inverse animation for hover-out */
    &:not(:hover) {
      background-image: linear-gradient(
        90deg,
        rgba(0, 229, 255, 1) 27%,
        rgba(255, 0, 199, 1) 52%,
        rgba(255, 199, 0, 1) 80%
      );
      background-clip: text;
      transform: scale(1);

      &::before {
        left: 30px;
        color: transparent;
      }

      &::after {
        right: 30px;
        color: transparent;
      }
    }
  }
}

@keyframes slashbefore {
  0% {
    left: 30px;
  }

  100% {
    left: 0px;
  }
}

@keyframes slashafter {
  0% {
    right: 30px;
  }

  100% {
    right: 0px;
  }
}

.button-container {
  margin: 0 30px;
  margin-left: 55px;
}

.active1 {
  color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(0, 229, 255, 1) 27%,
    rgba(255, 0, 199, 1) 52%,
    rgba(255, 199, 0, 1) 80%
  );
  background-clip: text;

  &::before,
  &::after {
    content: "/";
    color: white !important;
    font-weight: bolder;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover{
    &::before,
    &::after {
      opacity: 1;
    }
  }

  &::before {
    margin-right: 5px;
  }

  &::after {
    margin-left: 5px;
  }
}

.blur-background {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}
