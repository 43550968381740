.hero-section {
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .center-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url("./../../assets//image\ 17.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    img {
     
      @media (max-width: 800px) {
        width: 100%;
      }

      @media (max-width: 500px) {
        width: 80%;
      }
    }

    width: 40vw;
    @media (max-width: 800px) {
      width: 100%;
    }
    // width: 40%;
    h1 {
      font-size: 7rem;
    }

    div {
      margin-top: 60px;
      margin-bottom: -60px;
      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    span {
      color: #fff;
      font-family: 'Gilroy-Bold';
      font-size: 2.5rem;
      padding: 0px 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      
    }
  }
  .scroll-down {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 45px;
    cursor: pointer;
  }
  
  /* Integrating the provided animation */
  .scroll-down .arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  
    margin-top: 10px;
    text-align: center;
    margin: 8% 0;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  /* Keyframes for the bounce animation */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
}

.work {
  background-color: #1e0b01;
  min-height: 100vh;
}
