.work {
  padding: 65px 0 0 0;
  min-height: 100vh;

  @media (max-width:900px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    color: white;
    text-align: left;
    font-family: "Gilroy-Bold";
    text-transform: uppercase;
    font-weight: 400;

    .headingGradient {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(255, 0, 199, 1) 0%,

        rgba(255, 199, 0, 1) 41%
      );
      background-clip: text;
    }

    .headingGradient2 {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(0, 229, 255, 1) 40%,
        rgba(132, 0, 255, 1) 75%
      );
      background-clip: text;
    }
   
  }

  .glow-on-hover {
    font-size: 15px;
    height: 45px;
    width: 155px;
    border-radius: 8px;
    color: black;
    background: #fff;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 0;
    font-family: "Gilroy-Bold";
  
    &:before {
      content: "";
      background: linear-gradient(
        45deg,
        #00ffd5,
        #7a00ff,
        #ff00c8,
        #ff7300,
      );
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }
  
    &:hover:before {
      opacity: 1;
    }
  
    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      left: 0;
      top: 0;
      border-radius: 10px;
    }
  }

}

.connect-button{
    @media (max-width: 575.98px) {
      margin-top: 20px;
    }
  
    @media (min-width: 576px) and (max-width: 767.98px) {
      margin-top: 0px;
    }
  
    @media (min-width: 768px) and (max-width: 1199.98px) {
      margin-top: 10px;
    }
  
    @media (min-width: 1200px) {
      margin-top: 0px;
    }
    @media (min-width: 1900px) {
      margin-top: 40px;
    }
  }