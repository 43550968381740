.footer {
  padding: 5px 0;
  background-color:#010106;
  .company-email {
    color: white;
    font-family: Gilroy-Regular;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.1125rem;
    text-decoration-line: underline;
   
  }
.social-media-icons{
  color: white;
  font-family: Gilroy-Regular;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.1125rem;
  text-decoration-line: underline;
}

  .contact {
    .logo-text {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .side-text {
      color: #fff;
      font-family: Gilroy-Regular;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      text-transform: uppercase;
      margin-left: 5px;
    }

    .bottom-text {
      color: #fff;
      font-family: Gilroy-Regular;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem; /* 100% */
      text-transform: uppercase;
      text-decoration-line: none;
      margin-bottom: 10px;
    }
  }

  .footerSectionHeading {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5rem;
   
  }

  .footerSectionList {
    padding-left: 0;
    
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
   
  }

  .bottomText {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 100% */
    
    margin-left: 10px;
    cursor: pointer;
  }
}
