.contact-section {
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    display: contents;
    width: 65rem;
    height: 4.5rem;
    color: #fff;
    font-family: Gilroy-Bold;
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15rem;

    .headingGradient {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(132, 0, 255, 1) 10%,
        rgba(255, 0, 199, 1) 75%
      );
      background-clip: text;
    }

    @media (min-width: 1536px) {
      font-size: 3.5rem;
      letter-spacing: 0.27rem;
    }
  }

  img {
    width: 300px;
    height: 300px;
    margin-top: 95px;

    @media (min-width: 1536px) {
      width: 300px;
      height: 300px;
    }
  }

  .textField {
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
  }

  .whiteDropdown .MuiSelect-icon {
    color: white;
  }

  .submitButton {
    height: 58px;
    border-radius: 8px;
    color: black;
    background: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 20px;

    z-index: 0;
    font-family: "Gilroy-Bold";

    &:before {
      content: "";
      background: linear-gradient(45deg, #00ffd5, #7a00ff, #ff00c8, #ff7300);
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }

    &:hover:before {
      opacity: 1;
    }

    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      left: 0;
      top: 0;
      border-radius: 10px;
    }
  }
}

@media (max-width: 900px) {
  .grid {
    display: none;
  }
}

@media (max-width: 768px) {
  .center-box {
    min-width: 100%;

    h1 {
      font-size: 3rem !important;
    }

    p {
      font-size: 12px !important;
    }
  }
}
