.dialog__container{
    font-family: "Gilroy-Bold";
    font-size: 2rem;
    .dialog-content__container{
        padding: 1em 0 8em 1.5em;
        background-color: #0d0622;
        color: #fff;

        h2{
            font-weight: 500;
        }

        h5{
            font-weight: 500;
        }
    }

    .dialog-actions{
        background-color: #0d0622;        
        .btn{
            background-color: rgb(70, 69, 69);
            color: rgb(151, 151, 151);
        }
    }
}