.services__container {
  padding: 65px 0 0 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none;
  }

  h2 {
    font-weight: 500;
    font-family: "Gilroy-Bold";
    color: white;
    text-align: center;

    @media (max-width: 900px) {
      text-align: left;
    }

    .headingGradient {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(255, 0, 199, 1) 0%,
        rgba(255, 199, 0, 1) 41%
      );
      background-clip: text;
    }

    .headingGradient2 {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(0, 229, 255, 1) 40%,
        rgba(132, 0, 255, 1) 75%
      );
      background-clip: text;
    }
  }

  .services-card__container {
    display: flex;
    justify-content: center;
    align-items: center;

    .single-card__container {
      color: white;
      border: 2px solid #410134;
      background-color: #410134;
      margin: 0 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px;
      height: 6em;
      width: 15rem;

      &:hover {
        // border-bottom-right-radius: 32px;
        // border-top-left-radius: 32px;
        border-image: linear-gradient(
            to right,
            rgba(0, 229, 255, 1) 0%,
            rgba(255, 0, 199, 1) 35%,
            rgba(255, 199, 0, 1) 100%
          )
          1;
      }

      h5 {
        font-weight: 400 !important;
      }
    }
  }
}

.expandedStyles {
  padding: 20px 25px;
  margin: 0 5px;
  width: 20em;
  height: 14em;
  color: #fff;
  display: flex;
  flex-direction: column;
  background-color: #410134;
  border: 2px solid #290221;
  border-image: linear-gradient(
      to right,
      rgba(0, 229, 255, 1) 0%,
      rgba(255, 0, 199, 1) 35%,
      rgba(255, 199, 0, 1) 100%
    )
    1;

  .expanded-heading__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin: 0 0 15px 0;
    // border: 2px solid red;

    img {
      width: 2.8em;
    }

    h5 {
      font-size: 1.5rem;
    }
  }
}
