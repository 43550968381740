.navlinkMob {
    margin: 0 50px;
    cursor: pointer;
    width: 41px;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    font-family: Gilroy-Bold ,Arial, Helvetica, sans-serif;
    transition: color 0.3s ease-in-out, background-image 0.1s ease-in-out,
      background-clip 0.1s ease-in-out;


    &:hover {
      color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(0, 229, 255, 1) 27%,
        rgba(255, 0, 199, 1) 52%,
        rgba(255, 199, 0, 1) 80%
      );
      background-clip: text;

      &::before {
        content: "/";
        color: white !important;
        margin-right: 1px;
        font-weight: bolder;
        position: relative;
        animation-name: slashbefore;
        animation-iteration-count: 1;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
      }

      @keyframes slashbefore {
        0% {
          left: 30px;
        }

        100% {
          left: 0px;
        }
      }

      &::after {
        content: "/";
        color: white !important;
        margin-left: 1px;
        font-weight: bolder;
        position: relative;
        animation-name: slashafter;
        animation-iteration-count: 1;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
      }

      @keyframes slashafter {
        0% {
          right: 30px;
        }

        100% {
          right: 0px;
        }
      }
    }
  }