.industries {
  padding: 4rem 0 0 4rem;

  @media (max-width: 575.98px) {
    padding-top: 20px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding-top: 0px;
  }

  @media (min-width: 768px) and (max-width: 1199.98px) {
    padding-top: 10px;
  }

  @media (min-width: 1200px) {
    padding-top: 250px;
  }

  @media (min-width: 1900px) {
    padding-top: 40px;
  }

  h2 {
    color: white;
    text-align: left;
    width: 100%;
    font-weight: 400;
    font-family: "Gilroy-Bold";
    text-transform: uppercase;

    .headingGradient {
      color: transparent;
      background-image: linear-gradient(90deg,
          rgba(0, 229, 255, 1) 40%,
          rgba(132, 0, 255, 1) 75%);

      background-clip: text;
    }

    .headingGradient2 {
      color: transparent;
      background-image: linear-gradient(90deg,
          rgba(255, 0, 199, 1) 0%,

          rgba(255, 199, 0, 1) 41%);
      background-clip: text;
    }
  }

  .industry-card {
    .industry-card-details {

      @media (min-width: 1200px) {
        height: 450px;
        overflow: scroll;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}